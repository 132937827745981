// nav toggle
const smallNav = document.getElementById("small-nav");
let prevNavState = false;
smallNav.style.right = "-20rem";
const toggleNav = () => {
  if (prevNavState) {
    smallNav.style.right = "-20rem";
    prevNavState = !prevNavState;
  } else {
    smallNav.style.right = "0";
    prevNavState = !prevNavState;
  }
};
document.getElementById("navMenuButton").addEventListener("click", toggleNav);

const hideNav = () => {
  if (prevNavState) {
    smallNav.style.right = "-20rem";
    prevNavState = !prevNavState;
  }
};

// nav hide on focus change
const content = document.getElementById("content");
content.addEventListener("mouseup", hideNav);

// hide nav on scroll
const navbar = document.getElementById("navbar");
let prevScrollPos = window.pageYOffset;
window.onscroll = function () {
  hideNav();
  var currentScrollPos = window.pageYOffset;
  if (prevScrollPos > currentScrollPos) {
    navbar.style.top = "0";
  } else {
    navbar.style.top = "-20rem";
  }
  prevScrollPos = currentScrollPos;
};

// competencies toggle
const competenciesButtonText = document.getElementById(
  "competenciesButtonText"
);
const competenciesParagraphExpanded = document.getElementById(
  "competenciesParagraphExpanded"
);
const competenciesParagraphShort = document.getElementById(
  "competenciesParagraphShort"
);
let comStatus = false;
document.getElementById("competenciesButton").addEventListener("click", () => {
  competenciesParagraphShort.classList.toggle("hidden");
  competenciesParagraphShort.classList.toggle("inline-block");

  competenciesParagraphExpanded.classList.toggle("hidden");
  competenciesParagraphExpanded.classList.toggle("inline-block");

  comStatus = !comStatus;
  if (comStatus) {
    competenciesButtonText.innerText = "Prikaži manj";
  } else {
    competenciesButtonText.innerText = "Preberite več";
  }
});

// background height
const heroContainer = document.getElementById("hero-container");
const topBackground = document.getElementById("top-background");
const topFiller = document.getElementById("top-filler");

const resizeHelper = () => {
  const heroEnd = heroContainer.offsetTop + heroContainer.offsetHeight;
  const backgroundHeight = topBackground.offsetHeight;
  const fillerHeight = heroEnd - backgroundHeight;
  topFiller.style.height = fillerHeight + 50 + "px";
};
resizeHelper();
window.addEventListener("resize", resizeHelper);

// 100vh work around
const heroGrid = document.getElementById("hero-grid");
const setHeight = () => {
  heroGrid.style.minHeight = (window.innerHeight - 80) + "px";
};
setHeight();
window.addEventListener("resize", setHeight);
